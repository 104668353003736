///////////////////////////////////////////////////////////////////////////////////MODULES
import React, { useEffect } from "react";
import { Provider } from "react-redux";
////////////////////////////////////////////////////////////////////////////////APP ROUTER
import AppRouter from "./routers/AppRouter";
/////////////////////////////////////////////////////////////////////////////////////STORE
import configureStore from "./store/configureStore";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUser } from "./actions/user";
//////////////////////////////////////////////////////////////////////////////////////////

document.getElementsByName("viewport")[0].content = "width=device-width, initial-scale=1, maximum-scale=1";

const store = configureStore();
const App = () => {
  useEffect(() => {
    const request = async () => {
      try {
        await store.dispatch(getUser());
      } catch (error) {
        console.error(error);
      }
    };

    request();
  }, []);

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
