////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { connect } from "react-redux";
import { CustomButton, FormGroup } from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { googleLogin, googleBackup, restore } from "../../actions/user";
//////////////////////////////////////////////////////////////////////////////////////////

const GooglePage = ({ googleLogin, googleBackup, restore }) => {
  // onClick functions
  const onGoogleLoginClick = async (e) => {
    try {
      await googleLogin();
    } catch (e) {
      console.error(e);
    }
  };

  const onBackupClick = async (e) => {
    try {
      await googleBackup();
    } catch (e) {
      console.error(e);
    }
  };

  const onRestoreClick = async (e) => {
    try {
      await restore();
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <FormGroup>
          <CustomButton color="primary" black onClick={onGoogleLoginClick}>
            Google Login
          </CustomButton>
        </FormGroup>

        <FormGroup>
          <CustomButton color="primary" onClick={onBackupClick}>
            Backup to Google Drive
          </CustomButton>
          <CustomButton onClick={onRestoreClick}>Restore from backup</CustomButton>
        </FormGroup>
        <br />
      </Section>
    </FullPage>
  );
};

export default connect(null, { googleLogin, googleBackup, restore })(GooglePage);
