///////////////////////////////////////////////////////////////////////////////ALERT TYPES
export const GET_MESSAGES = "GET_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
////////////////////////////////////////////////////////////////////////////////USER TYPES
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_FAILURE = "PUT_USER_FAILURE";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
////////////////////////////////////////////////////////////////////////////////DEBT TYPES
export const GET_DEBTS_SUCCESS = "GET_DEBTS_SUCCESS";
export const GET_DEBTS_FAILURE = "GET_DEBTS_FAILURE";
export const POST_DEBT_SUCCESS = "POST_DEBT_SUCCESS";
export const POST_DEBT_FAILURE = "POST_DEBT_FAILURE";
export const PUT_DEBT_SUCCESS = "PUT_DEBT_SUCCESS";
export const PUT_DEBT_FAILURE = "PUT_DEBT_FAILURE";
export const DELETE_DEBT_SUCCESS = "DELETE_DEBT_SUCCESS";
export const DELETE_DEBT_FAILURE = "DELETE_DEBT_FAILURE";
//////////////////////////////////////////////////////////////////////////////INCOME TYPES
export const GET_INCOMES_SUCCESS = "GET_INCOMES_SUCCESS";
export const GET_INCOMES_FAILURE = "GET_INCOMES_FAILURE";
export const POST_INCOME_SUCCESS = "POST_INCOME_SUCCESS";
export const POST_INCOME_FAILURE = "POST_INCOME_FAILURE";
export const PUT_INCOME_SUCCESS = "PUT_INCOME_SUCCESS";
export const PUT_INCOME_FAILURE = "PUT_INCOME_FAILURE";
export const DELETE_INCOME_SUCCESS = "DELETE_INCOME_SUCCESS";
export const DELETE_INCOME_FAILURE = "DELETE_INCOME_FAILURE";
//////////////////////////////////////////////////////////////////////////////REVIEW TYPES
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILURE = "GET_REVIEWS_FAILURE";
export const POST_REVIEW_SUCCESS = "POST_REVIEW_SUCCESS";
export const POST_REVIEW_FAILURE = "POST_REVIEW_FAILURE";
export const PUT_REVIEW_SUCCESS = "PUT_REVIEW_SUCCESS";
export const PUT_REVIEW_FAILURE = "PUT_REVIEW_FAILURE";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_FAILURE = "DELETE_REVIEW_FAILURE";
///////////////////////////////////////////////////////////////////////////////EMAIL TYPES
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";
export const PUSH_EMAIL_SUCCESS = "PUSH_EMAIL_SUCCESS";
export const PUSH_EMAIL_FAILURE = "PUSH_EMAIL_FAILURE";
//////////////////////////////////////////////////////////////////////////////////////////
