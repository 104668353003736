/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
///////////////////////////////////////////////////////////////////INCOMES DEFAULT STATE
const incomesDefaultState = [];
/////////////////////////////////////////////////////////////////////////INCOMES REDUCER
const incomes = (state = incomesDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_INCOMES_SUCCESS:
      return Array.isArray(action.incomes) ? [...action.incomes] : [action.incomes];
    case types.GET_INCOMES_FAILURE:
      return [...state];

    // POST request
    case types.POST_INCOME_SUCCESS:
      return [...state, action.income];
    case types.POST_INCOME_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_INCOME_SUCCESS:
      return state.map((income) => {
        if (income._id === action.income._id) {
          return { ...income, ...action.income };
        } else {
          return income;
        }
      });
    case types.PUT_INCOME_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_INCOME_SUCCESS:
      return state.filter((income) => income._id !== action.income._id);
    case types.DELETE_INCOME_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default incomes;
