///////////////////////////////////////////////////////////////////////////////////MODULES
import { BrowserRouter, Route, Routes } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////////PAGES
import HomePage from "../components/Pages/HomePage";
import IncomePage from "../components/Pages/IncomePage";
import DebtPage from "../components/Pages/DebtPage";
import GitPage from "../components/Pages/GitPage";
import ReviewsPage from "../components/Pages/ReviewsPage";
import GooglePage from "../components/Pages/GooglePage";
import AuthPage from "../components/Pages/Authentication/AuthPage";
import PrivacyPolicyPage from "../components/Pages/Legals/PrivacyPolicyPage";
import CookiesPolicyPage from "../components/Pages/Legals/CookiesPolicyPage";
import TermsConditions from "../components/Pages/Legals/TermsConditions";
import DisclaimerPage from "../components/Pages/Legals/DisclaimerPage";
import ReturnPolicyPage from "../components/Pages/Legals/ReturnPolicyPage";
import NotFoundPage from "../components/Pages/NotFoundPage";
import FailedLoginPage from "../components/Pages/Authentication/FailedLoginPage";
import SuccessfulLoginPage from "../components/Pages/Authentication/SuccessfulLoginPage";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from "../components/Layout/Header";
import Alert from "../components/Layout/Alert";
import Footer from "../components/Layout/Footer";
import ScrollUp from "../components/Layout/ScrollUp";
//////////////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => (
  <BrowserRouter>
    <>
      <ScrollUp />
      <Header />
      <Alert />
      <Routes>
        {/* Pages */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/income" element={<IncomePage />} />
        <Route exact path="/debt" element={<DebtPage />} />
        <Route exact path="/git" element={<GitPage />} />
        <Route exact path="/reviews" element={<ReviewsPage />} />
        <Route exact path="/google" element={<GooglePage />} />
        {/* Auth Pages */}
        <Route exact path="/auth" element={<AuthPage />} />
        <Route exact path="/failed-login" element={<FailedLoginPage />} />
        <Route exact path="/successful-login" element={<SuccessfulLoginPage />} />
        {/* Legal Pages */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/cookie-policy" element={<CookiesPolicyPage />} />
        <Route exact path="/terms-conditions" element={<TermsConditions />} />
        <Route exact path="/disclaimer" element={<DisclaimerPage />} />
        <Route exact path="/return-policy" element={<ReturnPolicyPage />} />
        {/* 404 */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  </BrowserRouter>
);

export default AppRouter;
