///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CustomNav } from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { logout } from "../../actions/user";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ user, logout }) => {
  // State
  const [offcanvas, setOffcanvas] = useState(false);

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <CustomNav
        dark={c.nav.dark}
        blur={c.nav.blur}
        navbarBrand={c.name}
        navbarBrandHref="#"
        navbarBrandOnClick={(_) => navigate("/")}
        fadeOnPaths={c.nav.fadeOnPaths}
        navlinks={[]}
        navlinksRight={[
          {
            title: "Login",
            href: "#",
            onClick: (_) => {
              navigate("/auth");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: user?.isAuthenticated,
          },

          // Auth links
          {
            title: user && user?.user ? `${user?.user?.name}@${c.name}` : "",
            href: "#",
            hide: !user?.isAuthenticated,
            onClick: (_) => {
              if (user?.isAuthenticated) {
                navigate("/user");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 1);
              }
            },
          },
          {
            title: "Logout",
            href: "#",
            hide: !user?.isAuthenticated,
            onClick: (_) => {
              if (user?.isAuthenticated) {
                logout();
              }
            },
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logout })(Header);
