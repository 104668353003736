/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
////////////////////////////////////////////////////////////////////////AUTH DEFAULT STATE
const userDefaultState = {
  isAuthenticated: false,
  user: null,
};
//////////////////////////////////////////////////////////////////////////////AUTH REDUCER
const user = (state = userDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Register
    case types.REGISTER_USER_SUCCESS:
      return { ...state, user: null, isAuthenticated: false };

    // Login
    case types.LOGIN_USER_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // GET request
    case types.GET_USER_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // PUT request
    case types.PUT_USER_SUCCESS:
      return { ...state, user: payload.user };
    case types.PUT_USER_FAILURE:
      return { ...state };

    // Auth failure & logout & DELETE request
    case types.LOGIN_USER_FAILURE:
    case types.REGISTER_USER_FAILURE:
    case types.GET_USER_FAILURE:
    case types.LOGOUT_USER_SUCCESS:
    case types.LOGOUT_USER_FAILURE:
      return { ...state, user: null, isAuthenticated: false };

    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };

    case types.DELETE_USER_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default user;
