///////////////////////////////////////////////////////////////////////////////////MODULES
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
//////////////////////////////////////////////////////////////////////////////////REDUCERS
import user from "../reducers/user";
import debts from "../reducers/debts";
import incomes from "../reducers/incomes";
import reviews from "../reducers/reviews";
import alert from "../reducers/alert";
//////////////////////////////////////////////////////////////////////////////////////////

const composeEnhancers =
  (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const configureStore = () => {
  const store = createStore(
    combineReducers({ user, debts, incomes, reviews, alert }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default configureStore;
