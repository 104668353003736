import { scroll, IOS17Filled } from "@ibiliaze/react-base";
import constants from "@ibiliaze/react-constants";
import afenia from "./configs/afenia";

export const env = process.env;

const config = env.REACT_APP_CONFIG === "afenia" ? afenia : null;

const c = constants(
  config({
    scroll,
    IOS17Filled: ({ name, size, color }) => <IOS17Filled name={name} size={size} color={color} />,
  })
);

export default c;
