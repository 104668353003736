////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Col,
  CustomButton,
  CustomInput,
  CustomListGroup,
  CustomListGroupItem,
  Form,
  FormGroup,
  Label,
  ListGroupItemText,
  Row,
} from "@ibiliaze/reactstrap";
import { Fluency, FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getIncomes, postIncome, putIncome, deleteIncome } from "../../actions/incomes";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = { name: "", type: "", amount: "", date: "", expense: false, icon: "" };
const IncomePage = ({ incomes, getIncomes, postIncome, putIncome, deleteIncome }) => {
  // State
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [edit, setEdit] = useState({ income: false });
  const [total, setTotal] = useState({ income: 0, expenses: 0 });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => await getIncomes(`?sortBy=date`))();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    try {
      const incomeT = incomes.reduce((sum, expense) => sum + (expense.expense ? 0 : expense.amount), 0);
      const expenseT = incomes.reduce((sum, expense) => sum + (expense.expense ? expense.amount : 0), 0);
      setTotal({ income: incomeT, expenses: expenseT });
    } catch (e) {
      console.error(e);
    }
  }, [incomes]);

  // onClick functions
  const onIncomeClick = async (income) => {
    try {
      setEdit((c) => ({ ...c, income: true }));
      setInputs((c) => ({ ...c, ...income }));
    } catch (e) {
      setEdit((c) => ({ ...c, income: false }));
      console.error(e);
    }
  };

  const onDeleteIncomeClick = async (e) => {
    try {
      await deleteIncome(inputs._id);
      setEdit((c) => ({ ...c, income: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      setEdit((c) => ({ ...c, income: false }));
      console.error(e);
    }
  };

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.income ? putIncome : postIncome;
      await req({ incomeId: inputs._id, ...inputs });

      setEdit((c) => ({ ...c, income: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, income: false }));
      setInputs({ ...inputsDefault });
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <h2>Income</h2>
              <FormGroup>
                <div>
                  Total Income:{" "}
                  <span style={{ color: "green" }}>
                    {c.currency.currencySign}
                    {(typeof total.income === "number" ? total.income : 0).toFixed(2)}
                  </span>
                </div>
                <div>
                  Total Expenses:{" "}
                  <span style={{ color: "red" }}>
                    {c.currency.currencySign}
                    {(typeof total.expenses === "number" ? total.expenses : 0).toFixed(2)}
                  </span>
                </div>
                <div>
                  Gross Total:{" "}
                  <span>
                    {c.currency.currencySign}
                    {(typeof (total.expenses === "number" && total.income === "number")
                      ? total.income - total.expenses
                      : 0
                    ).toFixed(2)}
                  </span>
                </div>
              </FormGroup>
              <CustomListGroup>
                {incomes?.map((income, i) => (
                  <CustomListGroupItem key={i} onClick={(_) => onIncomeClick(income)}>
                    <ListGroupItemText>
                      <Fluency name={income?.icon} size={40} /> {income?.name}{" "}
                      <span style={{ float: "right", color: income?.expense ? "red" : "green" }}>
                        {income?.expense ? "-" : "+"}
                        {c.currency.currencySign}
                        {income?.amount}
                      </span>
                      <div>
                        {income?.date}
                        {income?.date === 1 || income?.date === 21 || income?.date === 31
                          ? "st"
                          : income?.date === 2 || income?.date === 22
                          ? "nd"
                          : income?.date === 3 || income?.date === 23
                          ? "rd"
                          : "th"}
                      </div>
                    </ListGroupItemText>
                  </CustomListGroupItem>
                ))}
              </CustomListGroup>
            </Col>
            <Col md={6}>
              <div className="stick-to-top">
                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Name *"
                    name="name"
                    value={inputs.name}
                    onChange={onInputsChange}
                  />
                  <Label>Name *</Label>
                </FormGroup>

                <FormGroup>
                  <CustomButton
                    color="primary"
                    onClick={(_) => setInputs((c) => ({ ...c, expense: !inputs.expense }))}
                  >
                    {inputs.expense ? "Income" : "Expense"}
                  </CustomButton>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Type"
                    name="type"
                    type="select"
                    value={inputs.type}
                    onChange={onInputsChange}
                  >
                    <option value="">Select</option>
                    <option>Loan</option>
                    <option>Rent</option>
                    <option>Mortgage</option>
                    <option>Finance</option>
                    <option>Website Client</option>
                    <option>Dev Expense</option>
                  </CustomInput>
                  <Label>Type</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Amount *"
                    type="number"
                    name="amount"
                    value={inputs.amount}
                    onChange={onInputsChange}
                  />
                  <Label>Amount *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Date *"
                    type="number"
                    name="date"
                    value={inputs.date}
                    onChange={onInputsChange}
                  />
                  <Label>Date *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput placeholder="Icon" name="icon" value={inputs.icon} onChange={onInputsChange} />
                  <Label>Icon</Label>
                </FormGroup>

                <FormGroup>
                  <Fluency name={inputs?.icon} size={40} />
                </FormGroup>

                <FormGroup>
                  <CustomButton type="submit" color="primary" black>
                    Save
                  </CustomButton>
                  {edit.income && (
                    <>
                      <CustomButton color="danger" onClick={onDeleteIncomeClick}>
                        Delete
                      </CustomButton>
                    </>
                  )}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Form>
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ incomes: state.incomes });

export default connect(mapStateToProps, { getIncomes, postIncome, putIncome, deleteIncome })(IncomePage);
