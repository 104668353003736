///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/income
export const getIncomesSuccess = (incomes) => ({ type: types.GET_INCOMES_SUCCESS, incomes });
export const getIncomesFailure = (errorMessage) => ({ type: types.GET_INCOMES_FAILURE, errorMessage });
export const getIncomes = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/income` : `${resolveHost()}/api/income${endpoint}`
    );

    dispatch(getIncomesSuccess(res.data));
  } catch (e) {
    dispatch(getIncomesFailure(e.message));
  }
};

// POST /api/income
export const postIncomeSuccess = (income) => ({ type: types.POST_INCOME_SUCCESS, income });
export const postIncomeFailure = (errorMessage) => ({ type: types.POST_INCOME_FAILURE, errorMessage });
export const postIncome = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the income...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/income`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_INCOME_SUCCESS));
    dispatch(postIncomeSuccess(res.data.income));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_INCOME_FAILURE));
    dispatch(postIncomeFailure(e.message));
  }
};

// PUT /api/income
export const putIncomeSuccess = (income) => ({ type: types.PUT_INCOME_SUCCESS, income });
export const putIncomeFailure = (errorMessage) => ({ type: types.PUT_INCOME_FAILURE, errorMessage });
export const putIncome = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the income...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/income/${data.incomeId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_INCOME_SUCCESS));
    dispatch(putIncomeSuccess(res.data.income));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_INCOME_FAILURE));
    dispatch(putIncomeFailure(e.message));
  }
};

// DELETE /api/income
export const deleteIncomeSuccess = (income) => ({ type: types.DELETE_INCOME_SUCCESS, income });
export const deleteIncomeFailure = (errorMessage) => ({ type: types.DELETE_INCOME_FAILURE, errorMessage });
export const deleteIncome = (incomeId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the income...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/income/${incomeId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_INCOME_SUCCESS));
    dispatch(deleteIncomeSuccess(res.data.income));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_INCOME_FAILURE)
    );
    dispatch(deleteIncomeFailure(e.message));
  }
};
