////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Badge,
  Col,
  CustomButton,
  CustomInput,
  CustomListGroup,
  CustomListGroupItem,
  CustomMultiSelect,
  Form,
  FormGroup,
  Label,
  ListGroupItemText,
  Row,
} from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getReviews, postReview, putReview, deleteReview } from "../../actions/reviews";
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  name: "",
  address: "",
  postcode: "",
  tel: "",
  email: "",
  phone: "",
  phoneId: "",
  reviewsWritten: [],
};
const ReviewsPage = ({ reviews, getReviews, postReview, putReview, deleteReview }) => {
  // State
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [reviewsWritten, setReviewsWritten] = useState([]);
  const [edit, setEdit] = useState({ review: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => await getReviews(`?sortBy=date`))();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // onClick functions
  const onReviewClick = async (review) => {
    try {
      setEdit((c) => ({ ...c, review: true }));
      setInputs((c) => ({ ...c, ...review }));
      setReviewsWritten([...review.reviewsWritten]);
    } catch (e) {
      setEdit((c) => ({ ...c, review: false }));
      console.error(e);
    }
  };

  const onDeleteReviewClick = async (e) => {
    try {
      await deleteReview(inputs._id);
      setEdit((c) => ({ ...c, review: false }));
      setInputs({ ...inputsDefault });
      setReviewsWritten([]);
    } catch (e) {
      setEdit((c) => ({ ...c, review: false }));
      console.error(e);
    }
  };

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.review ? putReview : postReview;
      await req({ reviewId: inputs._id, ...inputs, reviewsWritten });

      setEdit((c) => ({ ...c, review: false }));
      setInputs({ ...inputsDefault });
      setReviewsWritten([]);
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, review: false }));
      setInputs({ ...inputsDefault });
      setReviewsWritten([]);
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <h2>Reviews</h2>
              <CustomListGroup>
                {reviews?.map((review, i) => (
                  <CustomListGroupItem key={i} onClick={(_) => onReviewClick(review)}>
                    <ListGroupItemText>
                      {review?.name}{" "}
                      <div>
                        {review?.reviewsWritten?.map((rr, i) => (
                          <>
                            <Badge
                              key={i}
                              color={
                                rr?.value?.includes("London Mile")
                                  ? "primary"
                                  : rr?.value?.includes("Eight Mile")
                                  ? "warning"
                                  : "secondary"
                              }
                            >
                              {rr?.value}
                            </Badge>{" "}
                          </>
                        ))}
                      </div>
                    </ListGroupItemText>
                  </CustomListGroupItem>
                ))}
              </CustomListGroup>
            </Col>
            <Col md={6}>
              <div className="stick-to-top">
                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Name *"
                    name="name"
                    value={inputs.name}
                    onChange={onInputsChange}
                  />
                  <Label>Name *</Label>
                </FormGroup>

                <FormGroup>
                  <Label>Reviews Written</Label>
                  <CustomMultiSelect
                    value={reviewsWritten}
                    onChange={setReviewsWritten}
                    options={[
                      { value: "London Mile - Checkatrade", label: "London Mile - Checkatrade" },
                      { value: "London Mile - Google", label: "London Mile - Google" },
                      { value: "London Mile - Trustpilot", label: "London Mile - Trustpilot" },
                      { value: "Eight Mile - Checkatrade", label: "Eight Mile - Checkatrade" },
                      { value: "Eight Mile - Google", label: "Eight Mile - Google" },
                      { value: "Eight Mile - Trustpilot", label: "Eight Mile - Trustpilot" },
                    ]}
                  />
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Address"
                    name="address"
                    value={inputs.address}
                    onChange={onInputsChange}
                  />
                  <Label>Address</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Postcode"
                    name="postcode"
                    value={inputs.postcode}
                    onChange={onInputsChange}
                  />
                  <Label>Postcode</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    type="tel"
                    placeholder="Tel"
                    name="tel"
                    value={inputs.tel}
                    onChange={onInputsChange}
                  />
                  <Label>Tel</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Email"
                    name="email"
                    value={inputs.email}
                    onChange={onInputsChange}
                  />
                  <Label>Email</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Phone"
                    name="phone"
                    value={inputs.phone}
                    onChange={onInputsChange}
                  />
                  <Label>Phone</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Phone ID"
                    name="phoneId"
                    value={inputs.phoneId}
                    onChange={onInputsChange}
                  />
                  <Label>Phone ID</Label>
                </FormGroup>

                <FormGroup>
                  <CustomButton type="submit" color="primary" black>
                    Save
                  </CustomButton>
                  {edit.review && (
                    <>
                      <CustomButton color="danger" onClick={onDeleteReviewClick}>
                        Delete
                      </CustomButton>
                    </>
                  )}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Form>
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ reviews: state.reviews });

export default connect(mapStateToProps, { getReviews, postReview, putReview, deleteReview })(ReviewsPage);
