///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/review
export const getReviewsSuccess = (reviews) => ({ type: types.GET_REVIEWS_SUCCESS, reviews });
export const getReviewsFailure = (errorMessage) => ({ type: types.GET_REVIEWS_FAILURE, errorMessage });
export const getReviews = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/review` : `${resolveHost()}/api/review${endpoint}`
    );

    dispatch(getReviewsSuccess(res.data));
  } catch (e) {
    dispatch(getReviewsFailure(e.message));
  }
};

// POST /api/review
export const postReviewSuccess = (review) => ({ type: types.POST_REVIEW_SUCCESS, review });
export const postReviewFailure = (errorMessage) => ({ type: types.POST_REVIEW_FAILURE, errorMessage });
export const postReview = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the review...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/review`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_REVIEW_SUCCESS));
    dispatch(postReviewSuccess(res.data.review));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_REVIEW_FAILURE));
    dispatch(postReviewFailure(e.message));
  }
};

// PUT /api/review
export const putReviewSuccess = (review) => ({ type: types.PUT_REVIEW_SUCCESS, review });
export const putReviewFailure = (errorMessage) => ({ type: types.PUT_REVIEW_FAILURE, errorMessage });
export const putReview = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the review...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/review/${data.reviewId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_REVIEW_SUCCESS));
    dispatch(putReviewSuccess(res.data.review));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_REVIEW_FAILURE));
    dispatch(putReviewFailure(e.message));
  }
};

// DELETE /api/review
export const deleteReviewSuccess = (review) => ({ type: types.DELETE_REVIEW_SUCCESS, review });
export const deleteReviewFailure = (errorMessage) => ({ type: types.DELETE_REVIEW_FAILURE, errorMessage });
export const deleteReview = (reviewId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the review...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/review/${reviewId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_REVIEW_SUCCESS));
    dispatch(deleteReviewSuccess(res.data.review));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_REVIEW_FAILURE)
    );
    dispatch(deleteReviewFailure(e.message));
  }
};
