////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState } from "react";
import { connect } from "react-redux";
import { Col, CustomButton, CustomInput, FormGroup, InputGroup, Row } from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { triggerNpmUpdate, pipelineHousekeeping } from "../../actions/gitlab";
//////////////////////////////////////////////////////////////////////////////////////////

const GitlabPage = ({ triggerNpmUpdate, pipelineHousekeeping }) => {
  // State
  const [inputs, setInputs] = useState({ package: "" });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPipelineNpmSyncClick = async () => {
    try {
      await triggerNpmUpdate({ pipeline: "sync", callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onPipelineNpmSyncUpdate = async () => {
    try {
      await triggerNpmUpdate({ pipeline: "update", package: inputs.package, callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteOldPipelineslick = async () => {
    try {
      await pipelineHousekeeping({ callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Row>
          <Col md={6}>
            <h2>NPM Update</h2>
            <FormGroup>
              <CustomButton color="primary" onClick={onPipelineNpmSyncClick}>
                Trigger Sync
              </CustomButton>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <CustomInput
                  name="package"
                  value={inputs.package}
                  onChange={onInputsChange}
                  placeholder="Package"
                />
                <CustomButton color="primary" onClick={onPipelineNpmSyncUpdate}>
                  Trigger Update
                </CustomButton>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <h2>Housekeeping</h2>
            <FormGroup>
              <CustomButton color="primary" onClick={onDeleteOldPipelineslick}>
                Delete Old Pipelines
              </CustomButton>
            </FormGroup>
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

export default connect(null, { triggerNpmUpdate, pipelineHousekeeping })(GitlabPage);
