///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/debt
export const getDebtsSuccess = (debts) => ({ type: types.GET_DEBTS_SUCCESS, debts });
export const getDebtsFailure = (errorMessage) => ({ type: types.GET_DEBTS_FAILURE, errorMessage });
export const getDebts = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/debt` : `${resolveHost()}/api/debt${endpoint}`
    );

    dispatch(getDebtsSuccess(res.data));
  } catch (e) {
    dispatch(getDebtsFailure(e.message));
  }
};

// POST /api/debt
export const postDebtSuccess = (debt) => ({ type: types.POST_DEBT_SUCCESS, debt });
export const postDebtFailure = (errorMessage) => ({ type: types.POST_DEBT_FAILURE, errorMessage });
export const postDebt = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the debt...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/debt`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_DEBT_SUCCESS));
    dispatch(postDebtSuccess(res.data.debt));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_DEBT_FAILURE));
    dispatch(postDebtFailure(e.message));
  }
};

// PUT /api/debt
export const putDebtSuccess = (debt) => ({ type: types.PUT_DEBT_SUCCESS, debt });
export const putDebtFailure = (errorMessage) => ({ type: types.PUT_DEBT_FAILURE, errorMessage });
export const putDebt = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the debt...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/debt/${data.debtId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_DEBT_SUCCESS));
    dispatch(putDebtSuccess(res.data.debt));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_DEBT_FAILURE));
    dispatch(putDebtFailure(e.message));
  }
};

// DELETE /api/debt
export const deleteDebtSuccess = (debt) => ({ type: types.DELETE_DEBT_SUCCESS, debt });
export const deleteDebtFailure = (errorMessage) => ({ type: types.DELETE_DEBT_FAILURE, errorMessage });
export const deleteDebt = (debtId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the debt...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/debt/${debtId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_DEBT_SUCCESS));
    dispatch(deleteDebtSuccess(res.data.debt));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_DEBT_FAILURE));
    dispatch(deleteDebtFailure(e.message));
  }
};
