///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// POST /api/gitlab/pipeline/npm-update/update
export const triggerNpmUpdate = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Triggering NPM Update pipeline...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/gitlab/pipeline/npm-update/update`, data);

    await data.callback(res.data);

    dispatch(setAlert(res.data.message, false, res.status, null));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, null));
  }
};

// POST /api/gitlab/pipeline/housekeeping
export const pipelineHousekeeping = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Performing Pipeline Housekeeping...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/gitlab/pipeline/housekeeping`, data);

    await data.callback(res.data);

    dispatch(setAlert(res.data.message, false, res.status, null));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, null));
  }
};
