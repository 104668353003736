/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
///////////////////////////////////////////////////////////////////DEBTS DEFAULT STATE
const debtsDefaultState = [];
/////////////////////////////////////////////////////////////////////////DEBTS REDUCER
const debts = (state = debtsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_DEBTS_SUCCESS:
      return Array.isArray(action.debts) ? [...action.debts] : [action.debts];
    case types.GET_DEBTS_FAILURE:
      return [...state];

    // POST request
    case types.POST_DEBT_SUCCESS:
      return [...state, action.debt];
    case types.POST_DEBT_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_DEBT_SUCCESS:
      return state.map((debt) => {
        if (debt._id === action.debt._id) {
          return { ...debt, ...action.debt };
        } else {
          return debt;
        }
      });
    case types.PUT_DEBT_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_DEBT_SUCCESS:
      return state.filter((debt) => debt._id !== action.debt._id);
    case types.DELETE_DEBT_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default debts;
