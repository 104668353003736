///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

export const getUserSuccess = (payload) => ({ type: types.GET_USER_SUCCESS, payload });
export const getUserFailure = (errorMessage) => ({ type: types.GET_USER_FAILURE, errorMessage });
export const getUser = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/user` : `${resolveHost()}/api/user${endpoint}`
    );

    dispatch(getUserSuccess(res.data));
  } catch (e) {
    dispatch(getUserFailure(e.message));
  }
};

// Register
export const registerSuccess = (payload) => ({ type: types.REGISTER_USER_SUCCESS, payload });
export const registerFailure = (errorMessage) => ({ type: types.REGISTER_USER_FAILURE, errorMessage });
export const register = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Creating account...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.REGISTER_USER_SUCCESS));
    dispatch(registerSuccess(res.data));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.REGISTER_USER_FAILURE)
    );
    dispatch(registerFailure(e.message));
  }
};

// Login
export const loginSuccess = (payload) => ({ type: types.LOGIN_USER_SUCCESS, payload });
export const loginFailure = (errorMessage) => ({ type: types.LOGIN_USER_FAILURE, errorMessage });
export const login = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Signing in...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/token`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGIN_USER_SUCCESS));
    dispatch(loginSuccess(res.data));
    dispatch(getUser());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.LOGIN_USER_FAILURE));
    dispatch(loginFailure(e.message));
  }
};

// Logout
export const logoutSuccess = (payload) => ({ type: types.LOGOUT_USER_SUCCESS, payload });
export const logoutFailure = (errorMessage) => ({ type: types.LOGOUT_USER_FAILURE, errorMessage });
export const logout = () => async (dispatch) => {
  try {
    dispatch(setAlert("Signing out...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/logout`);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGOUT_USER_SUCCESS));
    dispatch(logoutSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.LOGOUT_USER_FAILURE));
    dispatch(logoutFailure(e.message));
  }
};

// PUT /api/user
export const putUserSuccess = (payload) => ({ type: types.PUT_USER_SUCCESS, payload });
export const putUserFailure = (errorMessage) => ({ type: types.PUT_USER_FAILURE, errorMessage });
export const putUser = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the account...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/user`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_USER_SUCCESS));
    dispatch(putUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_USER_FAILURE));
    dispatch(putUserFailure(e.message));
  }
};

// PUT /api/user/password
export const putUserPassword = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the password...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/user/password`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_USER_SUCCESS));
    dispatch(putUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_USER_FAILURE));
    dispatch(putUserFailure(e.message));
  }
};

// DELETE /api/user
export const deleteUserSuccess = (payload) => ({ type: types.DELETE_USER_SUCCESS, payload });
export const deleteUserFailure = (errorMessage) => ({ type: types.DELETE_USER_FAILURE, errorMessage });
export const deleteUser = () => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the account...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/user`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_USER_SUCCESS));
    dispatch(deleteUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_USER_FAILURE));
    dispatch(deleteUserFailure(e.message));
  }
};

// POST /api/user/google/login
export const googleLogin = () => async (dispatch) => {
  try {
    dispatch(setAlert("Logging in to Google...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/google/login`);

    if (res.data.url) {
      window.location.href = res.data.url;
    } else {
      dispatch(setAlert("Failed to generate consent page.", true, null));
    }

    dispatch(setAlert(res.data.message, false, res.status, null));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, null));
  }
};

// POST /api/user/google/backup
export const googleBackup = () => async (dispatch) => {
  try {
    dispatch(setAlert("Backing up to Google...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/google/backup`);

    dispatch(setAlert(res.data.message, false, res.status, null));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, null));
  }
};

// POST /api/user/google/backup/restore
export const restore = () => async (dispatch) => {
  try {
    dispatch(setAlert("Restoring from backup...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/google/backup/restore`);

    dispatch(setAlert(res.data.message, false, res.status, null));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, null));
  }
};
