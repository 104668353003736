////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Col,
  CustomButton,
  CustomInput,
  CustomListGroup,
  CustomListGroupItem,
  Form,
  FormGroup,
  Label,
  ListGroupItemText,
  Row,
} from "@ibiliaze/reactstrap";
import { Fluency, FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getDebts, postDebt, putDebt, deleteDebt } from "../../actions/debts";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  name: "",
  type: "",
  amount: 0,
  recurringPayment: false,
  recurringPaymentDate: 0,
  recurringPaymentAmount: 0,
  icon: "",
};
const DebtPage = ({ debts, getDebts, postDebt, putDebt, deleteDebt }) => {
  // State
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [edit, setEdit] = useState({ debt: false });
  const [total, setTotal] = useState({ debt: 0 });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => await getDebts(`?sortBy=amount`))();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    try {
      const debtT = debts.reduce((sum, expense) => sum + (expense.expense ? 0 : expense.amount), 0);
      setTotal({ debt: debtT });
    } catch (e) {
      console.error(e);
    }
  }, [debts]);

  // onClick functions
  const onDebtClick = async (debt) => {
    try {
      setEdit((c) => ({ ...c, debt: true }));
      setInputs((c) => ({ ...c, ...debt }));
    } catch (e) {
      setEdit((c) => ({ ...c, debt: false }));
      console.error(e);
    }
  };

  const onDeleteDebtClick = async (e) => {
    try {
      await deleteDebt(inputs._id);
      setEdit((c) => ({ ...c, debt: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      setEdit((c) => ({ ...c, debt: false }));
      console.error(e);
    }
  };

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.debt ? putDebt : postDebt;
      await req({ debtId: inputs._id, ...inputs });

      setEdit((c) => ({ ...c, debt: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, debt: false }));
      setInputs({ ...inputsDefault });
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <h2>Debt</h2>
              <FormGroup>
                <div>
                  Total Debt:{" "}
                  <span>
                    {c.currency.currencySign}
                    {(typeof total.debt === "number" ? total.debt : 0).toFixed(2)}
                  </span>
                </div>
              </FormGroup>
              <CustomListGroup>
                {debts?.map((debt, i) => (
                  <CustomListGroupItem key={i} onClick={(_) => onDebtClick(debt)}>
                    <ListGroupItemText>
                      <Fluency name={debt?.icon} size={40} /> {debt?.name}{" "}
                      <span style={{ float: "right" }}>
                        {c.currency.currencySign}
                        {debt?.amount}
                      </span>
                    </ListGroupItemText>
                  </CustomListGroupItem>
                ))}
              </CustomListGroup>
            </Col>
            <Col md={6}>
              <div className="stick-to-top">
                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Name *"
                    name="name"
                    value={inputs.name}
                    onChange={onInputsChange}
                  />
                  <Label>Name *</Label>
                </FormGroup>

                <FormGroup>
                  <CustomButton
                    color="primary"
                    onClick={(_) => setInputs((c) => ({ ...c, recurringPayment: !inputs.recurringPayment }))}
                  >
                    {inputs.recurringPayment ? "Not recurring" : "Recurring"}
                  </CustomButton>
                </FormGroup>

                {inputs.recurringPayment && (
                  <>
                    <FormGroup floating>
                      <CustomInput
                        required
                        placeholder="Amount paid monthly *"
                        type="number"
                        name="recurringPaymentAmount"
                        value={inputs.recurringPaymentAmount}
                        onChange={onInputsChange}
                      />
                      <Label>Amount paid monthly *</Label>
                    </FormGroup>

                    <FormGroup floating>
                      <CustomInput
                        required
                        placeholder="Date *"
                        type="number"
                        name="recurringPaymentDate"
                        value={inputs.recurringPaymentDate}
                        onChange={onInputsChange}
                      />
                      <Label>Date *</Label>
                    </FormGroup>
                  </>
                )}

                <FormGroup floating>
                  <CustomInput
                    placeholder="Type"
                    name="type"
                    type="select"
                    value={inputs.type}
                    onChange={onInputsChange}
                  >
                    <option value="">Select</option>
                    <option>Loan</option>
                    <option>Rent</option>
                    <option>Mortgage</option>
                    <option>Finance</option>
                    <option>Website Client</option>
                    <option>Dev Expense</option>
                  </CustomInput>
                  <Label>Type</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Amount *"
                    type="number"
                    name="amount"
                    value={inputs.amount}
                    onChange={onInputsChange}
                  />
                  <Label>Amount *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput placeholder="Icon" name="icon" value={inputs.icon} onChange={onInputsChange} />
                  <Label>Icon</Label>
                </FormGroup>

                <FormGroup>
                  <Fluency name={inputs?.icon} size={40} />
                </FormGroup>

                <FormGroup>
                  <CustomButton type="submit" color="primary" black>
                    Save
                  </CustomButton>
                  {edit.debt && (
                    <>
                      <CustomButton color="danger" onClick={onDeleteDebtClick}>
                        Delete
                      </CustomButton>
                    </>
                  )}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Form>
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ debts: state.debts });

export default connect(mapStateToProps, { getDebts, postDebt, putDebt, deleteDebt })(DebtPage);
